module.exports = {
  siteTitle: 'Matthew Riley MacPherson is on the Internet.',
  siteDescription: `Matt is a software developer, motorcyclist, DIYer, and general obsessive who is optimistic to a fault.`,
  keyWords: [],
  authorName: 'Matthew Riley MacPherson',
  twitterUsername: 'tofumatt',
  githubUsername: 'tofumatt',
  authorAvatar: '/images/matt.jpg',
  social: {
    twitter: 'https://twitter.com/tofumatt',
    github: 'https://github.com/tofumatt',
    email: 'hi@tofumatt.com',
  },
  siteUrl: 'https://tofumatt.com',
  pathPrefix: '', // Note: it must *not* have a trailing slash.
  siteCover: '/images/background.jpg',
  background_color: '#ffffff',
  theme_color: '#25303B',
  fontColor: '#000000cc',
  enableDarkmode: true, // If true, enables dark mode switch
  display: 'minimal-ui',
  headerLinks: [
    {
      label: 'Home',
      url: '/',
    },
    {
      label: 'Octopus Think',
      url: 'https://octopusthink.com',
    },
  ],
}
